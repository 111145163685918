// Nav labels
const aboutLink = document.getElementById('about-link')
const contactLink = document.getElementById('contact-link')
const solutionsLink = document.querySelector('.solutions-link')
const aboutLink2 = document.getElementById('about-link2')
const contactLink2 = document.getElementById('contact-link2')
const solutionsLink2 = document.getElementById('solutions-link2')
const ecommerceLink = document.getElementById('ecommerce-link')
const paidServiceLink = document.getElementById('paid-service-link')
const paymentLink = document.getElementById('payment-link')
const buttonsLanguageSpanEs = document.querySelectorAll('.button-language-span-es')
const buttonsLanguageSpanEn = document.querySelectorAll('.button-language-span-en')

// Footer labels
const servicesLink = document.getElementById('services-footer-link')
const resourcesLink = document.getElementById('resources-footer-link')
const aboutFooterLink = document.getElementById('about-footer-link')
const contactFooterLink = document.getElementById('contact-footer-link')
const nexsletterParagraph = document.querySelector('.nexsletter-paragraph')
const nexsletterBtn = document.querySelector('.nexsletter-submit')
const copyrightParagraph = document.querySelector('.copyright-paragraph')
const subsAlert = document.getElementById('subscription-alert')

// Home labels
// hero:
const easyLabel = document.querySelector('.fcil-rpido-y')
const sendLabel = document.querySelector('.enva-y-recibe')
const neverLabel = document.querySelector('.con-guatapay-nunca')
const easyLabelMobile = document.querySelector('.fcil-rpido-y1')
const sendLabelMobile = document.querySelector('.enva-y-recibe1')
const neverLabelMobile = document.querySelector('.con-guatapay-nunca1')
const moreInfoLabel = document.querySelectorAll('.label3')

// llegamos a cada rincon:
const comingTitleLabel = document.querySelector('.llegamos-a-cada')
const eeuuLabel = document.getElementById('eeuu-label')
const peLabel = document.getElementById('pe-label')
const brLabel = document.getElementById('br-label')
const esLabel = document.getElementById('es-label')
const comingLabel = document.getElementById('coming-label')
const comingParagraph = document.querySelector('.malesuada-fames-ac')

// sin complejidades:
const easyLabel2 = document.querySelector('.fcil-rpido-y2')
const complexitiesLabel = document.querySelector('.sin-complejidades1')
const moveLabel = document.querySelector('.mueve-tu-dinero')
const registerLabel = document.querySelector('.label1')
const costLabel = document.querySelector('.costo-promedio-por')
const availableLabel = document.querySelector('.disponible-1065231')
const easyLabel3 = document.getElementById('fcil-rpido-y3')
const complexitiesLabel2 = document.querySelector('.sin-complejidades2')
const moveLabel2 = document.querySelector('.mueve-tu-dinero1')
const costLabel2 = document.querySelector('.costo-promedio-por1')

// testimonial:
const testimonyParagraph = document.querySelector('.guatapay-me-da')
const testimonyContext = document.getElementById('testimony-context')

// solicitar demo:
const requestParagraph = document.querySelector('.olvdate-de-las')
const registerLabel2 = document.querySelector('.label7')

// About labels
// about container:
const aboutTitle = document.querySelector('.about-title')
const aboutParagraph = document.querySelector('.about-paragraph')

// guatapay container:
const guatapaySpan = document.querySelector('.guatapay-info-span')
const guatapayLabel = document.querySelector('.long-screen-info')
const guatapayLabelMobile = document.querySelector('.mobile-info')

// we believe container:
const weBelieveParagraph = document.querySelector('.we-believe-paragraph')

// Currencies container:
const currenciesParagraph = document.querySelector('.currencies-paragraph')
const comingSoonIcon = document.querySelector('.comming-soon-icon')

// our service container:
const ourServiceParagraph = document.querySelector('.our-service-paragraph')

// Solutions labels
const learnMoreLink = document.querySelectorAll('.solutions-content-link')
// solutions:
const solutionTitle = document.querySelector('.solutions-title')
const solutionParagraph = document.querySelector('.solutions-paragraph')

// solutions container
const solutionContainerTitle = document.getElementById('solutions-title')
const solutionConainerParagraph = document.getElementById('solutions-paragraph')

// solutions container 1
const solutionContainerOneTitle = document.getElementById('solutions-one-title')
const solutionContainerOneParagraph = document.getElementById('solutions-one-paragraph')

// solutions container 2
const solutionContainerTwoTitle = document.getElementById('solutions-two-title')
const solutionContainerTwoParagraph = document.getElementById('solutions-two-paragraph')

// registrate container
const registerParagraph = document.querySelector('.registrate-paragraph')
const registerLink = document.querySelector('.registrate-link')

// contact us labels
// header:
const formHeaderTitle = document.querySelector('.form-header-title')
const formHeaderParagraph = document.querySelector('.form-header-paragraph')

// form:
const formNameSpan = document.getElementById('form-name')
const formCompanySpan = document.getElementById('form-company')
const formPhoneSpan = document.getElementById('form-phone')
const formMessageSpan = document.getElementById('form-message')
const formPolicies = document.querySelector('.policy-paragraph')
const formNamePlaceholder = document.getElementById('name')
const formEmailPlaceholder = document.getElementById('email')
const formCompanyPlaceholder = document.getElementById('compañia')
const formMessagePlaceholder = document.getElementById('mensaje')
const formSubmitValue = document.querySelector('.submit-btn')
